// Entry point for the build script in your package.json

import currency from "currency.js";
import toastr from "toastr";

import Rails from "@rails/ujs";
Rails.start();

require("./site/index");

$(document).on("change", "#cart_form input", function () {
  var elem = document.getElementById("cart_form");
  Rails.fire(elem, "submit");
});

$(document).on("change", "#search_form #filter_brand_id", function () {
  $("#filter_model_id").attr("disabled", "disabled");
  $("#filter_model_id").val(null).trigger("change");
  if (this.value) {
    $.get(`/catalog/models?brand_id=${this.value}`, function (data) {
      $("#filter_model_id option").remove();
      data.forEach(function (item) {
        var newOption = new Option(item[0], item[1], false, false);
        $("#filter_model_id").append(newOption).trigger("change");
      });
      var newOption = new Option("", "", true, true);
      $("#filter_model_id").prepend(newOption).trigger("change");

      // $("#filter_model_id").removeAttr("disabled");
    });
  }
});

// $(document).on(
//   "change",
//   '#search_form select, #search_form input[type="checkbox"], #search_form input[type="text"]',
//   function () {
//     $(".o-catalog-overlay").show();
//     var elem = document.getElementById("search_form"); // or $('#myform')[0] with jQuery
//     Rails.fire(elem, "submit");
//   }
// );

// $(document).on(
//   "ajax:beforeSend",
//   ".drop__down-link, .sort__i-label, .o-catalog__paginate .pagination a",
//   function (_xhr, _settings) {
//     $(".o-catalog-overlay").show();
//   }
// );

window.currency = currency;
window.toastr = toastr;

$(function () {
  if ($(".product__table").length) {
    $(".product__row-footer-value").html(
      currency(
        $.map($(".product__table .total_price"), function (item) {
          return parseFloat($(item).data("price"));
        }).reduce(function (pv, cv) {
          return pv + cv;
        }, 0),
        {
          precision: 0,
          separator: " ",
          symbol: "₽",
          pattern: `# !`,
          negativePattern: `- # !`,
        }
      ).format()
    );
  }
});

$(document).on("change", ".product__table .js-qnt-input", function () {
  let price = $(this)
    .parents(".product__row")
    .find(".product__cell_type_price-me .price")
    .data("price");
  let qty = $(this).val();

  let $total = $(this)
    .parents(".product__row")
    .find(".product__cell .total_price");

  $total.data("price", price * qty);
  $total.html(
    currency(price * qty, {
      precision: 0,
      separator: " ",
      symbol: "₽",
      pattern: `# !`,
      negativePattern: `- # !`,
    }).format()
  );

  $(".product__row-footer-value").html(
    currency(
      $.map($(".product__table .total_price"), function (item) {
        return parseFloat($(item).data("price"));
      }).reduce(function (pv, cv) {
        return pv + cv;
      }, 0),
      {
        precision: 0,
        separator: " ",
        symbol: "₽",
        pattern: `# !`,
        negativePattern: `- # !`,
      }
    ).format()
  );
});

// $(document).on("ajax:before", ".sort-link", function () {
//   // console.log('click');
//   $(".o-catalog-overlay").show();
// });
