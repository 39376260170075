import $ from "jquery";
import "select2";
import "slick-carousel";
import noUiSlider from "nouislider";
import wNumb from "./wNumb";
import Tooltip from "tooltip.js";
window.$ = window.jQuery = $;
require("@fancyapps/fancybox/dist/jquery.fancybox");
require("@chenfengyuan/datepicker/dist/datepicker.js");
require("@chenfengyuan/datepicker/i18n/datepicker.ru-RU");
require("select2/dist/js/i18n/ru.js");

$(function () {
  init_zoom();
  drop();
  toggle();
  select();
  offers_slider();
  intro_slider();
  articles_slider();
  brands_slider();
  catalog_mobile_slider();
  range_slider();
  offer_preview_slider();
  qnt();
  tooltip();
  // tabs();
  map();
  datepicker();
});

function datepicker() {
  $("[data-picker]").each(function () {
    var $el = $(this);
    $el.datepicker({
      format: $el.data("picker"),
      language: "ru-RU",
    });
  });
}

function init_zoom() {
  $(".js-modal").fancybox();
  $.fancybox.defaults.closeExisting = true;
}

window.drop = function () {
  var actCl = "drop_state_active";
  var timer = null;
  var $elOpened = null;

  var show = function ($el) {
    if ($elOpened) $elOpened.removeClass(actCl);
    $elOpened = $el;
    $el.addClass(actCl);
    resetTimer();
  };
  var hide = function ($el) {
    timer = setTimeout(function () {
      $el.removeClass(actCl);
      $elOpened = null;
    }, 500);
  };
  var resetTimer = function () {
    clearTimeout(timer);
  };

  $(document).on("click.drop", function (e) {
    if ($elOpened && $(e.target).closest(".js-drop").length == 0)
      $elOpened.removeClass(actCl);
  });

  $(".js-drop").each(function () {
    var $el = $(this);
    var $handler = $el.find(".js-drop-handler");
    var $content = $el.find(".js-drop-content");
    $handler.click(function (e) {
      e.stopPropagation();
      show($el);
    });
    $el.mouseleave(function () {
      hide($el);
    });
    $el.mouseenter(function () {
      resetTimer();
    });
  });
};

function toggle() {
  $(document).on("click.toggle", function (e) {
    $(".js-toggle[data-outer-click]").each(function () {
      var $el = $(this);
      var actCl = $el.data("class");
      var $block = $($el.data("toggle"));
      if ($(e.target).closest($block).length == 0) {
        $block.removeClass(actCl);
        scrollEnable();
      }
    });
  });

  var $scroll = $("body");
  var scrollDisableCl = "scroll-disable";

  var scrollDisable = function () {
    $scroll.addClass(scrollDisableCl);
  };
  var scrollEnable = function () {
    $scroll.removeClass(scrollDisableCl);
  };

  $(".js-toggle").each(function () {
    var $el = $(this);
    var actCl = $el.data("class");
    var $block = $($el.data("toggle"));
    $el.click(function (e) {
      e.stopPropagation();
      $block.toggleClass(actCl);
      if ($el.data("scroll-disable")) {
        if ($block.hasClass(actCl) && $el.data("scroll-disable"))
          scrollDisable();
        else scrollEnable();
      }
      var groupName = $el.data("group");
      if (groupName) {
        $(".js-toggle")
          .filter(function () {
            return (
              $(this).data("group") === groupName &&
              $(this).data("toggle") !== $el.data("toggle")
            );
          })
          .each(function () {
            var $elSecond = $(this);
            var $blockSecond = $($elSecond.data("toggle"));
            var actCl = $elSecond.data("class");
            $blockSecond.removeClass(actCl);
            scrollEnable();
          });
      }
    });
  });
}

function select() {
  $("select:not(.no-select2)").each(function () {
    $(this).select2({
      language: "ru",
      allowClear: true,
      minimumResultsForSearch: 3,
      placeholder: $(this).attr("placeholder"),
    });
    $(this).on('select2:open', function(){
      // console.log($('.select2-search__field').length);
      let nodes = document.querySelectorAll('.select2-search__field');
      if(nodes.length)
        nodes[nodes.length - 1].focus();
    });
  });
  $(".f-row__cell-btn-reset").on("click", function () {
    $("select:not(.no-select2)").val(null).trigger("change");
  });
}

function offers_slider() {
  $(".js-o-slider").each(function () {
    $(this)
      .find(".js-o-slider-list")
      .slick({
        speed: 500,
        centerMode: false,
        centerPadding: 0,
        variableWidth: false,
        infinite: false,
        adaptiveHeight: false,
        slidesToShow: 5,
        slidesToScroll: 5,
        prevArrow: $(this).find(".js-o-slider-prev"),
        nextArrow: $(this).find(".js-o-slider-next"),
        responsive: [
          {
            breakpoint: 1400,
            settings: {
              arrows: true,
              slidesToShow: 4,
              slidesToScroll: 4,
            },
          },
          {
            breakpoint: 1000,
            settings: {
              arrows: true,
              slidesToShow: 2,
              slidesToScroll: 2,
            },
          },
          {
            breakpoint: 768,
            settings: {
              arrows: false,
              slidesToShow: 1,
              slidesToScroll: 1,
              dots: true,
            },
          },
        ],
      });
  });
  $(".js-o-slider").on("setPosition", function () {
    $(this).find(".js-o-slider-list").height("auto");
    var slickTrack = $(this).find(".slick-track");
    var slickTrackHeight = $(slickTrack).height();
    $(this)
      .find(".slick-slide")
      .css("height", slickTrackHeight + "px");
  });
}

function articles_slider() {
  $(".js-a-slider").each(function () {
    if ($(".a-slider__list-i").length < 3) {
      $(this).find(".page__section-head-r").remove();
    } else {
      $(this)
        .find(".js-a-slider-list")
        .slick({
          speed: 500,
          centerMode: false,
          centerPadding: 0,
          variableWidth: false,
          infinite: false,
          adaptiveHeight: true,
          slidesToShow: 2,
          slidesToScroll: 2,
          prevArrow: $(this).find(".js-a-slider-prev"),
          nextArrow: $(this).find(".js-a-slider-next"),
          responsive: [
            {
              breakpoint: 1400,
              settings: {
                arrows: false,
                slidesToShow: 2,
                slidesToScroll: 2,
              },
            },
            {
              breakpoint: 1000,
              settings: "unslick",
            },
          ],
        });
    }
  });
}

function intro_slider() {
  $(".intro-slider").each(function () {
    setInterval(function () {
      var next_slide = $(
        ".intro-slider .intro-slider__i.active ~ .intro-slider__i"
      );
      if (next_slide.length == 0) {
        next_slide = $(".intro-slider .intro-slider__i:first-child");
      }
      var current_slide = $(".intro-slider .intro-slider__i.active");
      current_slide.toggleClass("active");
      next_slide.toggleClass("active");

      var next_dot = $(".intro-slider .intro-slider-dots .dot.active ~ .dot");
      if (next_dot.length == 0) {
        next_dot = $(".intro-slider .intro-slider-dots .dot:first-child");
      }
      var current_dot = $(".intro-slider .intro-slider-dots .dot.active");
      current_dot.toggleClass("active");
      next_dot.toggleClass("active");
    }, 4000);
  });
}

function brands_slider() {
  $(".js-b-slider").slick({
    speed: 500,
    centerMode: false,
    centerPadding: 0,
    variableWidth: false,
    infinite: false,
    adaptiveHeight: true,
    slidesToShow: 6,
    slidesToScroll: 6,
    arrows: false,
    appendDots: $(".js-b-slider-footer"),
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
        },
      },
    ],
  });
}

function catalog_mobile_slider() {
  $(".js-o-catalog-slider").slick({
    speed: 500,
    centerMode: false,
    centerPadding: 0,
    variableWidth: false,
    infinite: false,
    adaptiveHeight: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    dots: true,
    appendDots: $(".js-o-catalog-footer"),
    mobileFirst: true,
    responsive: [
      {
        breakpoint: 1000,
        settings: "unslick",
      },
    ],
  });
}

function offer_preview_slider() {
  $(".js-offer-slider-for").slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: false,
    fade: true,
    prevArrow: $(".js-offer-slider-prev"),
    nextArrow: $(".js-offer-slider-next"),
    asNavFor: ".js-offer-slider-nav",
    appendDots: ".js-offer-slider-footer",
    responsive: [
      {
        breakpoint: 1000,
        settings: {
          dots: true,
        },
      },
    ],
  });
  $(".js-offer-slider-nav").show().slick({
    slidesToShow: 4,
    slidesToScroll: 1,
    asNavFor: ".js-offer-slider-for",
    dots: false,
    arrows: false,
    centerMode: false,
    focusOnSelect: true,
  });
}

function range_slider() {
  $(".js-range-slide").each(function () {
    var $el = $(this);
    var $elFrom = $el.find(".js-range-slide-from");
    var $elTo = $el.find(".js-range-slide-to");
    var min = parseInt($el.data("min"));
    var max = parseInt($el.data("max"));
    var slider = noUiSlider.create(this, {
      start: [$elFrom.val(), $elTo.val()],
      connect: true,
      tooltips: [wNumb({ decimals: 0 }), wNumb({ decimals: 0 })],
      step: 1,
      range: {
        min: min,
        max: max,
      },
    });
    slider.on("change", function () {
      var range = slider.get();
      $elFrom.val(range[0]);
      $elTo.val(range[1]);
    });
  });
}

window.qnt = function () {
  $(".js-qnt").each(function () {
    var $el = $(this);
    var $input = $el.find(".js-qnt-input");
    var $value = $el.find(".js-qnt-value");
    var $up = $el.find(".js-qnt-up");
    var $down = $el.find(".js-qnt-down");
    var render = function () {
      $value.text($input.val() + " " + $el.data("unit"));
    };
    var getValue = function () {
      return parseInt($input.val());
    };
    render();
    $up.click(function () {
      var value = getValue();
      // console.log($input.attr('max'));
      if (value < $input.attr("max")) {
        $input.val(value + 1);
        $input.trigger("change");
      }
      render();
    });
    $down.click(function () {
      var value = getValue();
      if (value > $input.attr("min")) {
        $input.val(value - 1);
        $input.trigger("change");
      }
      render();
    });
    var $val = $el.find(".js-qnt-value").text();
    if (Number($val) == $input.attr("max")) {
      $up.css("color", "white");
    }
    // console.log(Number($val));
  });
  $(".js-qnt-product").each(function () {
    var $el = $(this);
    var $input = $el.find(".js-qnt-input");
    var $value = $el.find(".js-qnt-value");
    var $up = $el.find(".js-qnt-up");
    var $down = $el.find(".js-qnt-down");

    var render = function () {
      $value.text($input.val() + " " + $el.data("unit"));
    };
    var getValue = function () {
      return parseInt($input.val());
    };
    render();
    $up.click(function () {
      // console.log($input.attr("max"));
      var $val = $value.text();
      if (parseInt($val) == $input.attr("max")) {
        $el.find(".qnt__arrow_type_up").css("color", "white");
      } else {
        $el.find(".qnt__arrow_type_up").css("color", "black");
        $el.find(".qnt__arrow_type_down").css("color", "black");
      }
    });
    $down.click(function () {
      // console.log($input.attr("min"));
      var $val = $value.text();
      if (parseInt($val) == $input.attr("min")) {
        $el.find(".qnt__arrow_type_down").css("color", "white");
      } else {
        $el.find(".qnt__arrow_type_up").css("color", "black");
        $el.find(".qnt__arrow_type_down").css("color", "black");
      }
    });
  });
};

function tooltip() {
  $("[data-tooltip]").each(function () {
    var $el = $(this);
    var position = $el.data("tooltip-position") || "top";
    var text = $el.data("tooltip");
    var contentSelector = $el.data("tooltip-el");
    if (contentSelector) text = $(contentSelector).html();
    new Tooltip($el, {
      placement: position,
      title: text,
      html: true,
    });
  });
}

function tabs() {
  // Slider
  $(".js-tabs-navi").slick({
    speed: 500,
    centerMode: false,
    centerPadding: 0,
    variableWidth: false,
    infinite: false,
    adaptiveHeight: true,
    variableWidth: true,
    arrows: false,
    dots: false,
    mobileFirst: true,
    responsive: [
      {
        breakpoint: 1000,
        settings: "unslick",
      },
    ],
  });

  // Tabs
  var currentClass = "t-card__navi-i_state_current";
  var select = function ($handlers, $handler) {
    $handlers.removeClass(currentClass);
    $handlers.each(function () {
      var $el = $($(this).data("el"));
      if ($el) $el.addClass("hidden");
    });
    $handler.addClass(currentClass);
    var $el = $($handler.data("el"));
    if ($el) $el.removeClass("hidden");
  };
  $(".js-tabs").each(function () {
    var $wrap = $(this);
    var $handlers = $wrap.find(".js-tabs-handler");
    select($handlers, $handlers.eq(0));
    $handlers.click(function () {
      select($handlers, $(this));
    });
  });
}

function map() {
  var $map = $(".js-map");
  var $menu = $(".js-map-list");
  var $point = $(".js-map-point");

  if ($map.length === 0 || $menu.length === 0) return;

  var groups = $map.data("points");

  var init = function () {
    var myMap = new ymaps.Map(
      $map[0],
      {
        center: [56.826234, 35.887563],
        zoom: 14,
        controls: [],
      },
      {
        searchControlProvider: "yandex#search",
        maxZoom: 18,
      }
    );

    var createMenuGroup = function (group) {
      // Пункт меню.
      var menuItem = $(`
          <div class="contacts__group">
            <div class="contacts__title">${group.name}</div>
          </div>
        `),
        // Коллекция для геообъектов группы.
        collection = new ymaps.GeoObjectCollection(null),
        // Контейнер для подменю.
        submenu = $('<div class="contacts__list"></div>');

      // Добавляем коллекцию на карту.
      myMap.geoObjects.add(collection);
      // Добавляем подменю.
      menuItem
        .append(submenu)
        // Добавляем пункт в меню.
        .appendTo($menu);
      for (var j = 0, m = group.points.length; j < m; j++) {
        createSubMenu(group.points[j], collection, submenu);
      }

      // Выставляем масштаб карты чтобы были видны все группы.
      myMap.setBounds(myMap.geoObjects.getBounds());

      // for (var j = 0, m = group.extra_points.length; j < m; j++) {
      //   createSubMenu(group.extra_points[j], collection, submenu);
      // }
    };

    var createSubMenu = function (item, collection, submenu) {
      // Пункт подменю.
      var submenuItem = $(`<div class="contacts__i">${item.name}</div>`),
        // Создаем метку.
        placemark = new ymaps.Placemark(
          item.coordinates,
          {
            balloonContent: item.name,
          },
          {
            iconLayout: "default#image",
            iconImageHref: "/img/point.svg",
          }
        );

      placemark.events.add("click", function () {
        showPoint(item);
      });

      // Добавляем метку в коллекцию.
      collection.add(placemark);
      // Добавляем пункт в подменю.
      submenuItem
        .appendTo(submenu)
        // При клике по пункту подменю открываем/закрываем баллун у метки.
        .bind("click", function () {
          if (!placemark.balloon.isOpen()) {
            placemark.balloon.open();
            showPoint(item);
          } else {
            placemark.balloon.close();
            hidePoint();
          }
          return false;
        });
    };

    for (var i = 0, l = groups.length; i < l; i++) {
      createMenuGroup(groups[i]);
    }
  };

  ymaps.ready(init);

  var hidePoint = function () {
    $point.html("");
  };

  var showPoint = function (point) {
    var htmlTimework,
      htmlPhone,
      htmlType,
      htmlAddress = "";

    if (point.address)
      htmlAddress = `
          <div class="point__row">
            <div class="point__icon">
              <i class="icon-point"></i>
            </div>
            <div class="point__value point__value_type_address">
              ${point.address}
            </div>
          </div>
    `;

    if (point.type)
      htmlType = `
          <div class="point__row">
            <div class="point__icon"></div>
            <div class="point__value point__value_type_type">
              ${point.type}
            </div>
          </div>
    `;

    if (point.phone)
      htmlPhone = `
          <div class="point__row">
            <div class="point__icon">
              <i class="icon-phone"></i>
            </div>
            <div class="point__value">
              ${point.phone}
            </div>
          </div>
      `;

    if (point.timework) {
      var htmlState =
        '<div class="point__label point__label_color_red">Сейчас закрыто</div>';
      if (point.is_open)
        htmlState =
          '<div class="point__label point__label_color_green">Сейчас открыто</div>';
      htmlTimework = `
          <div class="point__row">
            <div class="point__icon">
              <i class="icon-clock"></i>
            </div>
            <div class="point__value">
              ${point.timework}
            </div>
          </div>
      `;
    }

    $point
      .html(
        `
      <div class="point">
        <div class="point__close js-map-point-close"></div>
        <div class="point__head" style="background-image: url(${point.src})"></div>
        <div class="point__content">
          ${htmlAddress}
          ${htmlType}
          ${htmlPhone}
          ${htmlTimework}
        </div>
      </div>
    `
      )
      .find(".js-map-point-close")
      .click(hidePoint);
  };
}

$(document).on("click", ".icon-search", function () {
  const search = document.querySelector(".header-search");
  search.classList.toggle("header-search_state_show");
});

$(document).on("click", ".search-tablet", function () {
  const search = document.querySelector(".header-search_tablet");
  search.classList.toggle("header-search_state_show");
});

$(window).scroll(function () {
  var height = $(window).scrollTop();
  if (height > 100) {
    $("#back_to_top").addClass("show");
  } else {
    $("#back_to_top").removeClass("show");
  }
});
$(document).ready(function () {
  $("#back_to_top").click(function (event) {
    event.preventDefault();
    $("html, body").animate({ scrollTop: 0 }, "slow");
    return false;
  });
});
